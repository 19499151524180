<template>
    <nav class="bg-white border-b">
        <div class="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
            <div class="flex h-14 justify-between">
                <div class="flex">
                    <router-link to="/" class="flex flex-shrink-0 items-center">
                        <img class="h-8 w-auto block" src="../assets/logo.svg" alt="xAurum"/>
                    </router-link>
                    <div class=" ml-3 sm:ml-6 flex space-x-3 sm:space-x-8">
                        <router-link to="/terms-of-use" active-class="!border-blue-500 text-gray-900"
                                     class="inline-flex items-center  px-1 pt-1 text-sm border-b-[3px] border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700">
                            Terms of use
                        </router-link>
                        <router-link to="/privacy-policy" active-class="!border-blue-500 text-gray-900"
                                     class="inline-flex items-center  px-1 pt-1 text-sm border-b-[3px] border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700">
                            Privacy policy
                        </router-link>
                        <router-link to="/feedback" active-class="!border-blue-500 text-gray-900" class="inline-flex items-center  px-1 pt-1 text-sm border-b-[3px] border-transparent text-gray-400 hover:border-gray-300 hover:text-gray-700">
                            Feedback
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<template>
    <NavbarComponent />
    
    <div class="container mx-auto p-8 md:p-0 mt-8">
        <router-view></router-view>
    </div>

    <Toaster position="top-right" />
</template>

<script>
import NavbarComponent from "@/components/NavbarComponent.vue";
import {Toaster} from "vue-sonner";
export default {
  name: 'App',
    components: {Toaster, NavbarComponent},
}
</script>

<script setup>
    const personalDataTable = {
        headers: [
            {
                key: 'purpose',
                label: 'Purpose for processing',
            },
            {
                key: 'category',
                label: 'Category of personal data processed',
            },
            {
                key: 'legal',
                label: 'Legal basis',
            },
        ],
        items: [
            {
                purpose: 'Client authentication',
                category: 'Personal Identification Information',
                legal: 'Performance of the Terms of Use',
            },
            {
                purpose: 'Client identity verification (KYC) for recovery',
                category: 'Personal Identification Information',
                legal: 'Performance of the Terms of Use',
            },
            {
                purpose: 'Client’s transaction history',
                category: 'Financial and Transaction data',
                legal: 'Performance of the Terms of Use',
            },
            {
                purpose: 'Responding to your enquiries and requests submitted via the Bot, sign-up forms, live chat or e-mail or any social media platforms',
                category: 'Communication Data, however, depending on the nature of your enquiry we can process all the data indicated in Section 2 above',
                legal: 'In case your question clearly relates to matters connected to the Terms of Use, Client Agreement or Purchase Agreement we process the data for the performance of the Contract. In other cases, we rely on our legitimate interests in ensuring effective relations management with all the interested parties in our Bot and services',
            },
            {
                purpose: 'Payment for service via payment service provider',
                category: 'Financial and Transaction Data, Personal Identification Information',
                legal: 'Performance of the Terms of Use',
            },
            {
                purpose: 'Handling the refunds related to Purchase Agreement',
                category: 'Personal Identification Information, Financial and Transaction Data, Communication Data',
                legal: 'Performance of the Purchase Agreement or in certain situations performance of our legal obligation',
            },
            {
                purpose: 'Enabling your use of the Trial',
                category: 'Personal Identification Information',
                legal: 'Performance of the Trial Terms',
            },
            {
                purpose: 'Enabling the Bot and its functionalities',
                category: 'Personal Identification Information, Financial and Transaction Data',
                legal: 'Performance of the Terms of Use and if relevant performance of the Purchase Agreement',
            },
            {
                purpose: 'Sending newsletters to your e-mail',
                category: 'Personal Identification Information',
                legal: 'Consent',
            },
            {
                purpose: 'Providing you with notifications via your chosen channel (for example, e-mail, Telegram Bot)',
                category: 'Personal Identification Information',
                legal: 'Consent given for the specific notification channel',
            },
            {
                purpose: 'Direct marketing campaigns - Client marketing campaigns in relation to the Bot, its functionalities and products already provided to you',
                category: 'Personal Identification Information, Financial and Transaction Data (mainly the transaction activity)',
                legal: 'Our legitimate interest in providing you with information relating to the services and products you have previously sourced from us',
            },
            {
                purpose: 'Processing data for analytics and insights, improvement and development of the Bot',
                category: 'All of the data categories indicated in Section 2 above',
                legal: 'Our legitimate interest in detecting and deterring suspicious and fraudulent actions related to the Bot',
            },
            {
                purpose: 'Diagnosing and repairing technical issues related to the Bot',
                category: 'Technical Data',
                legal: 'Our legitimate interest in providing data security and preventing fraudulent actions related to the Bot; ensuring the functioning of the Bot',
            },
            {
                purpose: 'Storing information containing personal data in backup systems',
                category: 'All of the data categories indicated in Section 2 above',
                legal: 'Our legitimate interest in ensuring the security of data processing operations',
            },
            {
                purpose: 'Data disclosures to our security auditors',
                category: 'All of the data categories indicated in Section 2 above',
                legal: 'Our legitimate interest in securing the information technology infrastructure and provided services',
            },
            {
                purpose: 'Mandatory disclosures to law enforcement and data protection authorities',
                category: 'All of the data categories indicated in Section 2 above',
                legal: 'Performance of our legal obligation',
            },
        ],
    }
    
    const sharingPersonalDataTable = {
        headers: [
            {
                key: 'category',
                label: 'Categories of Recipients',
            },
            {
                key: 'reason',
                label: 'Reason for sharing',
            },
            {
                key: 'type',
                label: 'Type of recipient',
            },
        ],
        items: [
            {
                category: 'Advertising partners',
                reason: 'We work with advertising partners to enable us to customize the advertising content you may receive. These partners help us deliver more relevant ads and promotional messages to you, which may include interest-based advertising (also known as online behavioral advertising), contextual advertising, and generic advertising. We and our advertising partners process certain personal data to help us understand your interests or preferences so that we can deliver advertisements that are more relevant to you.\n' +
                    'We also provide you promotional e-mail campaigns related to our services (educational videos etc.) by using e-mail campaign service provider. In order to do this we may transfer your e-mail address to such service provider so that they can send you the materials.',
                type: 'Data Processors',
            },
            {
                category: 'Professional advisors (legal advisors, accounting etc. bound to confidentiality)',
                reason: 'In case not operating as data processors, the legitimate interests in conducting and supporting our regular business activities.',
                type: 'Data Processors',
            }
        ]
    }
</script>

<template>
    <div class="mb-8">
        <h1 class="font-bold text-2xl text-gray-900">Privacy Policy</h1>
        <div class="mt-4 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">1. INTRODUCTION</h2>
            <p>Xaurumbot.com provides software as a service, which allows you to utilize several functionalities for managing your cryptocurrency holdings and other instruments through the Trading Bot. This Privacy Policy explains the principles on how Xaurum organisation, tax number 328527376, certificate No. 011-98490, issued in May 18, 2022 by Revenue Service of Georgia e-mail support@xaurumbot.com (“Xaurum”, “our”, “we” or “us”) as the personal data controller collects and processes your ("you") personal data when you visit telegram bot https://t.me/xAurumbot (“Bot”).</p>
            <p>Capitalised terms used in this Privacy Policy are used in the meaning given to them in the Terms of Use unless otherwise expressly set out herein.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">2. DATA WE COLLECT</h2>
            <p>We have set out in the table below the categories of personal data we collect and use about you:://t.me/xAurumbot (“Bot”).</p>
            <div class="mt-6 overflow-hidden">
                <div class="mx-auto max-w-full">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <table class="w-full text-left">
                            <tbody>
                            <tr>
                                <td rowspan="3" class="relative py-5 pr-6 font-mono border-b">
                                    When you visit our Website or contact with us
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Technical Data
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Upon visiting our Website, we process technical data related to your usage of the Website, including but not limited to IP address, location data (down to city level), access-provider, referring URL, date, time, access tokens, session key, browser type and version, browser language, operating system, amount and state of transferred data. This information can be related to you, therefore, Personal Identification Information can be processed as well. These data may also be processed as anonymized statistical data.
                                </td>
                            </tr>
                            <tr>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Cookie Data
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    We apply cookies on the Website, for optimising the Website and its functionalities. The cookies may collect your personal data. To learn more about the cookies we use, please read our Cookie Policy.
                                </td>
                            </tr>
                            <tr>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Communication Data
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    In case you interact with us via our Website live chat, e-mails and sign-up forms, Telegram or any other official social media account, we process, in addition to Personal Identification Information (limited in case of contacting via social media), also the contents of your message.
                                </td>
                            </tr>
                            <tr>
                                <td rowspan="3" class="relative py-5 pr-6 font-mono">
                                    When you use the Bot
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Personal Identification Information
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Telegram name, telegram id, telegram username, messenger language.
                                </td>
                            </tr>
                            <tr>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Financial and Transaction Data
                                </td>
                                <td class="relative py-5 pr-6 font-mono border-b">
                                    Exchange Account username, API key, API secret, passphrase, transaction data (date/time/amount of transaction), transaction request/response, Referral status, billing information.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p>The personal data we process is collected from one of the following sources:</p>
            <div class="ml-2">
                <p><span class="font-bold">—</span> the data is disclosed by you directly to us;</p>
                <p><span class="font-bold">—</span> we receive the data from your Exchange Account provider due to you connecting your Exchange Accounts to the Client Account;</p>
                <p><span class="font-bold">—</span> we receive the data from social media service provider due to you registering or contacting with us via your existing social media account;</p>
                <p><span class="font-bold">—</span> we receive the data from the payment service provider due to you concluding the Purchase Agreement and paying for the Subscription;</p>
                <p><span class="font-bold">—</span> we receive Technical Data automatically from your browser, our servers and systems;</p>
            </div>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">3. What we use your personal data for</h2>
            <p>We have set out in the table below the reasons why we process your personal data:</p>
            <div class="mt-6 overflow-hidden">
                <div class="mx-auto max-w-full">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <table class="w-full text-left">
                            <thead class="border-b">
                                <tr>
                                    <th v-for="header in personalDataTable.headers" :key="header.key" class="relative py-5 pr-6 font-mono text-left">
                                        {{ header.label }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in personalDataTable.items" :key="item.purpose" class="border-b">
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.purpose }}
                                </td>
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.category }}
                                </td>
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.legal }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p>We may process your personal data for other purposes, provided that we disclose the purposes and use to you at the relevant time, and that you either consent to the proposed use of the personal data, other legal grounds exist for the new processing purposes or the new purpose is compatible with the original purpose brought out above.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">4. SHARING YOUR PERSONAL DATA</h2>
            <p>Any data you provide will not be publicly displayed or shared to other Bot visitors or clients. Certain employees of Xaurumbot have access to personal data to the extent necessary for the performance of their work duties.</p>
            <p>We use third party processors and separate data controllers to help provide our service. They will have access to your personal data as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</p>
            <p>We have set out in the table below the reasons why and with whom we share your personal data:</p>
            <div class="mt-6 overflow-hidden">
                <div class="mx-auto max-w-full">
                    <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <table class="w-full text-left">
                            <thead class="border-b">
                            <tr>
                                <th v-for="header in sharingPersonalDataTable.headers" :key="header.key" class="relative py-5 pr-6 font-mono text-left">
                                    {{ header.label }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in sharingPersonalDataTable.items" :key="item.category" class="border-b">
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.category }}
                                </td>
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.reason }}
                                </td>
                                <td class="relative py-5 pr-6 font-mono">
                                    {{ item.type }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <p>In addition to the information provided in the table above, in some cases, we may transfer your personal data outside your region, if the recipient is located outside. We shall opt to use special personal data protection safeguards, in order to ensure the safety of your personal data. For obtaining further information on the processors and recipients engaged by us or if you wish to get acquainted with or obtain information on the transferring of your personal data outside your region and the safeguards implied thereof by contacting us using the contact information specified in this Privacy Policy.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">5. ENSURING THE SECURITY OF PERSONAL DATA</h2>
            <p>We have taken necessary technical and organizational security measures to protect your personal data against accidental or unlawful destruction, loss or alteration and against the unauthorized disclosure, abuse or other processing in violation of applicable law. We also encourage you to take measures to ensure the safety of your personal data. In particular, we advise you not to share your personal data with us or any of our partners via any public forums or other public channels, unless you acknowledge and accept that relevant data will be publicly accessible.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">6. RETENTION AND DELETION OF PERSONAL DATA</h2>
            <p>Your personal data (all data categories mentioned in Section 2) shall be stored insofar as reasonably necessary to attain the objectives stated in Section 3 above, or until the legal obligation stipulates that we do so. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the processing purposes and whether we can achieve these purposes through other means, and applicable statutory obligations. Whilst retaining the personal data, we take into account the viable need to resolve disputes and enforce the contract between us or anonymize your personal data and retain this anonymized information indefinitely.</p>
            <p>In case you are Client, as a general rule we will retain all your data for 7 days after the termination of the Client Agreement in a manner that would allow you to re-activate the Client Account. Otherwise, please see the following non-exhaustive summary on storing your personal data:</p>
            <div class="ml-4">
                <p>For accounting purposes, we retain Financial Data and Transaction Data and Personal Identification Information connected to it for a period of 7 years from the end of the financial year when the respective business transaction took place;</p>
                <p>Data connected to the Client Agreement or the Purchase Agreement, which is first and foremost Personal Identification Information, is retained for the whole period when the respective agreement is in force and at least 3 years from the moment of termination of the respective agreement under our legitimate interests to protection ourselves against potential disputes or enforce claims. In case we have a reasonable doubt that a party has acted in bad faith, has breached any obligations intentionally or has threatened us with a dispute, we may prolong such retention period for a maximum of 10 years.</p>
                <p>Technical Data will be retained for 30 days as of the collection of such data;</p>
                <p>Communication Data, unless clearly connected to the Client Agreement or the Purchase Agreement, will be retained for a period of 3 years from the moment the respective communication-flow has been closed.</p>
            </div>
            <p>In case any of the data stipulated in Section 2 above is needed for purposes of protection against ongoing or threatened disputes, we shall retain the related data as long as the dispute is solved.</p>
            <p>After the expiry of the retention period determined above or the termination of the legal basis for processing purpose, we may retain the materials containing the personal data in the backup systems, from which the respective materials will be deleted after the end of the backup cycle. We ensure that during the backup period appropriate safeguards are applied and the backed-up materials are put beyond use.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">7. YOUR RIGHTS AND PREFERENCES</h2>
            <p>Under data protection law, you have rights including:</p>
            <div class="ml-4">
                <p><i>Right to be informed and to access.</i> You may get information regarding your personal data processed by us.</p>
                <p><i>Right to data portability.</i> You have the right to receive your personal data from us in a structured, commonly used and machine-readable format. Moreover, you may request that the personal data is transmitted to another controller. Bear in mind that the latter can only be done if that is technically feasible.</p>
                <p><i>Right to erasure.</i> You the right to have personal data we process about you erased from our systems if the personal data are no longer necessary for the related purposes.</p>
                <p><i>Right to object and restrict.</i> You have the right to object to the processing of your personal data and restrict it in certain cases.</p>
                <p><i>Right to rectification.</i> You have the right to make corrections to your personal data.</p>
                <p><i>Right to withdraw consent.</i> When you have given us consent to process your personal data, you may withdraw said consent at any time.</p>
            </div>
            <p>To exercise any of the abovementioned rights, please contact our customer support team via e-mail indicated in Section 8 below.</p>
        </div>
        <div class="mt-8 space-y-2 text-sm text-gray-900">
            <h2 class="font-bold">8. OTHER IMPORTANT INFORMATION</h2>
            <p class="font-medium italic">Newsletter, notifications and direct marketing campaigns</p>
            <p>With your explicit consent, you may be subject to direct marketing campaigns, we may send you our newsletter or provide you with notifications. You may opt out of the direct marketing campaigns, newsletters and notifications on your account settings. We may also provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information.</p>
            <p>Please note that email marketing messages include an opt-out mechanism within the message itself (e.g. an unsubscribe link in the emails we send to you). Clicking on the link in an email will opt you out of further messages of that category. You can use the Account Settings directory to exercise choices about all categories of email and push marketing communication.</p>
            <p class="font-medium italic">Dispute resolution</p>
            <p>If you have questions, please feel free to contact us at support@xaurumbot.com.</p>
            <p>We may amend or modify this Privacy Policy from time to time to reflect changes in the way we process personal data. In case of material changes, we will notify you, as required under applicable laws.</p>
            <p class="font-medium italic">Age limitations</p>
            <p>We do not knowingly collect any information from individuals under 18 years of age. If we discover a user of being younger than 18 years old we will require the user to close their account and we will take steps to delete any collected information as soon as possible.</p>
        </div>
    </div>
</template>
import {createApp} from 'vue'
import './assets/css/style.css'
import {createRouter, createWebHistory} from 'vue-router'
import App from './App.vue'
import './styles/main.scss'
import directives from "./directives/";

import TermsOfUse from "@/pages/TermsOfUse.vue";
import PrivacyPolicy from "@/pages/PrivacyPolicy.vue";
import NotFound from "@/pages/NotFound.vue";
import FeedbackPage from "@/pages/FeedbackPage.vue";
import HomePage from "@/pages/HomePage.vue";

const routes = [
    {path: '/', component: HomePage},
    {path: '/terms-of-use', component: TermsOfUse},
    {path: '/privacy-policy', component: PrivacyPolicy},
    {path: '/feedback', component: FeedbackPage},
    {path: '/:pathMatch(.*)*', component: NotFound},
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const app = createApp(App)

directives(app)

app.use(router).mount('#app')

<script setup>
</script>

<template>
    <div class="relative isolate">
        <svg class="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]" aria-hidden="true">
            <defs>
                <pattern id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527" width="200" height="200" x="50%" y="-1" patternUnits="userSpaceOnUse">
                    <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
            </defs>
            <svg x="50%" y="-1" class="overflow-visible fill-gray-50">
                <path d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z" stroke-width="0" />
            </svg>
            <rect width="100%" height="100%" stroke-width="0" fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" />
        </svg>
        <div class="mx-auto max-w-7xl px-6 lg:flex lg:items-center lg:gap-x-10 lg:px-8">
            <div class="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <p class="inline bg-gradient-to-r from-indigo-200 via-sky-400 to-indigo-200 bg-clip-text font-display text-5xl tracking-tight text-transparent">
                    xAurum BOT
                </p>
                <p class="mt-3 text-2xl tracking-tight text-slate-400">
                    Automatic dollar cost averaging in Bitcoin on your exchange. Controlled directly from Telegram messenger. Try free trial.
                </p>

                <div class="mt-5 flex items-center gap-x-6">
                    <span v-tooltip.tooltip="'Tap here to use this bot'">
                        <a href="https://t.me/xAurumbot" class="w-full sm:w-[400px] text-center btn-telegram shine" role="button" target="_blank">Start</a>
                    </span>
                </div>
                <div class="mt-3 flex items-center gap-x-6">
                    <router-link to="/terms-of-use" class="transition-colors font-light text-gray-400 hover:text-gray-700">Terms of use</router-link>
                    <router-link to="/privacy-policy" class="transition-colors font-light text-gray-400 hover:text-gray-700">Privacy policy</router-link>
                </div>
            </div>
            <div class="mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow">
                <img class="mx-auto w-[20rem] max-w-full drop-shadow-xl" src="../assets/bot.svg" alt="xAurum Bot" />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>